// --------------------------------------------------
// APP.JS
// --------------------------------------------------

//
// Initialize Foundation
// --------------------------------------------------

$(document).foundation();
console.log('hi form jekkyll')
//
// Custom JS
// --------------------------------------------------
